import React, { useState, useEffect, useRef } from 'react'
import NavBar from '../components/NavBar'
import EventNavBar from '../components/EventNavBar';
import Image from '../assets/images/hotelimage/event.jpg'
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import Table from 'react-bootstrap/Table';
import Footer from '../components/Footer';
import LeftArrow from '../assets/images/leftarrow.png'
import RightArrow from '../assets/images/rightarrow.png'
import { events } from '../components/HotelImages'
import Conference2 from '../assets/images/conference2.jpg'
import Conference4 from '../assets/images/conference4.jpg'
import Conference5 from '../assets/images/conference5.jpg'
import Conference6 from '../assets/images/conference6.jpg'
import Conference3 from '../assets/images/conference3.jpg'
import Conference7 from '../assets/images/conference7.jpg'
import Ushape from '../assets/images/ushape.png'
import Theatre from '../assets/images/theatre.png'
import Boardroom from '../assets/images/boardroom.png'
import { useLocation } from 'react-router-dom';

function EventScreen() {

    const [screenSize, setScreenSize] = useState(getCurrentDimension());

    function getCurrentDimension() {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        }
    }

    useEffect(() => {
        const updateDimension = () => {
            setScreenSize(getCurrentDimension())
        }
        window.addEventListener('resize', updateDimension);


        return (() => {
            window.removeEventListener('resize', updateDimension);
        })
    }, [screenSize])

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const [currentIndex, setCurrentIndex] = useState(0);

    const goToPrevSlide = () => {
        const newIndex = (currentIndex - 1 + events.length) % events.length;
        setCurrentIndex(newIndex);
    };

    const goToNextSlide = () => {
        const newIndex = (currentIndex + 1) % events.length;
        setCurrentIndex(newIndex);
    };

    const [isHovered, setIsHovered] = useState(false);

    const handleHover = () => {
        setIsHovered(!isHovered);
    };

    const [isHovered1, setIsHovered1] = useState(false);

    const handleHover1 = () => {
        setIsHovered1(!isHovered1);
    };

    const scrollRef = useRef(null);

    useEffect(() => {
        console.log("Scroll ref:", scrollRef.current); // Debugging
    }, []);

    const handleScrollUp = () => {
        console.log("Scrolling up"); // Debugging
        if (scrollRef.current) {
            console.log("Before scrollUp:", scrollRef.current.scrollTop); // Debugging
            animateScroll(-40);
        }
    };

    const handleScrollDown = () => {
        console.log("Scrolling down"); // Debugging
        if (scrollRef.current) {
            console.log("Before scrollDown:", scrollRef.current.scrollTop); // Debugging
            animateScroll(40);
        }
    };

    const animateScroll = (scrollStep) => {
        const scrollElement = scrollRef.current;
        const start = scrollElement.scrollTop;
        const end = start + scrollStep;
        const duration = 500; // Adjust animation duration as needed
        let startTime = null;

        const scrollAnimation = (timestamp) => {
            if (!startTime) {
                startTime = timestamp;
            }

            const progress = timestamp - startTime;
            const scrollY = easeInOutQuad(progress, start, scrollStep, duration);
            scrollElement.scrollTop = scrollY;

            if (progress < duration) {
                requestAnimationFrame(scrollAnimation);
            }
        };

        requestAnimationFrame(scrollAnimation);
    };

    const easeInOutQuad = (t, b, c, d) => {
        t /= d / 2;
        if (t < 1) return (c / 2) * t * t + b;
        t--;
        return (-c / 2) * (t * (t - 2) - 1) + b;
    };

    const scrollToPosition = () => {
        const element = document.getElementById('targetElement');
        element.scrollIntoView({ behavior: 'smooth' });
    };

    const [zoomed, setZoomed] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setZoomed(prevZoomed => !prevZoomed); // Toggle zoomed state
        }, 3000); // Adjust the interval duration (in milliseconds) for the zoom animation

        return () => clearInterval(interval); // Clear the interval on component unmount
    }, []);

    const redirectToBook = () => {
        window.location.href = 'https://guest.reservations.ng/BRICKSPOINTBOUTIQUEAPARTHOTELAS0/step1'; // or use window.location.replace(url);
    };

    return (
        <div style={{ width: '100%' }}>
            <div style={{ width: '100%', height: screenSize.width < 700 ? 400 : 900, overflow: 'hidden' }} className='image-container1'>
                <div style={{ width: '100%', height: screenSize.width < 700 ? 400 : 900, position: 'relative', zIndex: 1500 }}>
                    <div style={{ width: '100%', height: 80, zIndex: 1500, backgroundColor: '#7C827C' }}>
                        <EventNavBar />
                    </div>
                    <img src={events[currentIndex].img} style={{ width: '100%', height: '100%', objectFit: 'cover', }} alt={`Slide ${currentIndex}`} />
                    {/* <div onClick={goToPrevSlide} style={{ position: 'absolute', width: 80, height: 80, border: '2px solid #fff', borderRadius: 100, top: '50%', left: '10px', transform: 'translateY(-50%)', zIndex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
                    <img src={LeftArrow} style={{ width: 50, height: 'auto', marginRight: -70 }} />
                </div> */}
                    <div
                        onClick={goToPrevSlide}
                        onMouseEnter={handleHover}
                        onMouseLeave={handleHover}
                        style={{
                            position: 'absolute',
                            width: screenSize.width < 700 ? 40 : 80,
                            height: screenSize.width < 700 ? 40 : 80,
                            border: '2px solid #fff',
                            borderRadius: 100,
                            top: '50%',
                            left: '10px',
                            transform: 'translateY(-50%)',
                            zIndex: 1,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',
                        }}
                    >
                        <img
                            src={LeftArrow}
                            style={{
                                width: screenSize.width < 700 ? 25 : 50,
                                height: 'auto',
                                marginRight: screenSize.width < 700 ? -35 : -70,
                                transition: 'margin-left 0.3s ease', // Add transition property for smooth animation
                                marginLeft: isHovered ? '-10px' : '0', // Apply margin-left when hovered
                            }}

                        />
                    </div>
                    <div
                        onClick={goToNextSlide}
                        onMouseEnter={handleHover1}
                        onMouseLeave={handleHover1}
                        style={{
                            position: 'absolute',
                            width: screenSize.width < 700 ? 40 : 80,
                            height: screenSize.width < 700 ? 40 : 80,
                            border: '2px solid #fff',
                            borderRadius: 100,
                            top: '50%',
                            right: '10px',
                            transform: 'translateY(-50%)',
                            zIndex: 1,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',
                        }}
                    >
                        <img
                            src={RightArrow}
                            style={{
                                width: screenSize.width < 700 ? 25 : 50,
                                height: 'auto',
                                marginLeft: screenSize.width < 700 ? -35 : -70,
                                transition: 'margin-right 0.3s ease', // Add transition property for smooth animation
                                marginRight: isHovered1 ? '-10px' : '0', // Apply marginRight when hovered
                            }}

                        />
                    </div>
                    <div style={{ zIndex: 0, width: '100%', position: 'absolute', height: screenSize.width < 700 ? 400 : 900, top: 0, bottom: 0, left: 0, right: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        <div style={{ fontSize: screenSize.width < 700 ? 24 : 48, fontFamily: 'FuturaLight', color: '#fff', textAlign: 'center' }}>EVENTS & MEETINGS</div>
                        <div style={{ width: 1, height: screenSize.width < 700 ? 35 : 70, backgroundColor: '#FFF', marginTop: screenSize.width < 700 ? 20 : 30 }}></div>
                        <div>
                            <div style={{ width: screenSize.width < 700 ? 125 : 250, height: screenSize.width < 700 ? 35 : 70, border: '0.5px solid #FFF', marginTop: screenSize.width < 700 ? 20 : 40, display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: screenSize.width < 700 ? 12 : 20, fontFamily: 'FuturaLight', color: '#fff', cursor: 'pointer', backgroundColor: '#7C827C' }} onClick={redirectToBook}>
                                RESERVE A HALL
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-around', marginTop: 100, flexDirection: screenSize.width < 700 ? 'column' : 'row', alignItems: screenSize.width < 700 ? 'center' : '' }}>
                <div style={{ width: screenSize.width < 700 ? 350 : 500, height: 450, backgroundColor: '#fff' }}>
                    <div style={{ fontSize: 18, color: '#000', fontFamily: 'FuturaLight', fontWeight: 'bold', width: '100%', textAlign: 'center' }}>MURTALA & KANO HALLS</div>
                    <div style={{ width: '100%', height: '70%', backgroundColor: '#fff', boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.2)', }}>
                        <img src={Ushape} style={{ width: '100%', height: '100%' }} />
                    </div>
                    <div style={{ width: '100%', height: '30%', backgroundColor: '#7C827C' }}>
                        <div style={{ paddingTop: 5, marginLeft: 30 }}>
                            <div style={{ fontSize: 18, color: '#FFF', fontFamily: 'FuturaLight', marginTop: 5 }}>IU-TYPE ARRANGEMENT</div>
                            <div style={{ fontSize: 14, color: '#FFF', fontFamily: 'FuturaLight', marginTop: 3 }}>SIZE - MURTALA 122.5 m<sup>2</sup> 34 PEOPLE</div>
                            <div style={{ fontSize: 14, color: '#FFF', fontFamily: 'FuturaLight', marginTop: 3 }}>SIZE - KANO 126.6 m<sup>2</sup> 23 PEOPLE</div>
                            <div style={{ fontSize: 14, color: '#FFF', fontFamily: 'FuturaLight', marginTop: 3 }}>SIZE - MURTALA & KANO 252.8 m<sup>2</sup> 39 PEOPLE</div>
                        </div>
                    </div>
                </div>

                <div style={{ width: screenSize.width < 700 ? 350 : 500, height: 450, backgroundColor: '#FFF', marginTop: screenSize.width < 700 ? 20 : 0 }}>
                    <div style={{ fontSize: 18, color: '#000', fontFamily: 'FuturaLight', fontWeight: 'bold', width: '100%', textAlign: 'center', marginTop: screenSize.width < 700 ? 20 : 0 }}>MURTALA & KANO HALLS</div>
                    <div style={{ width: '100%', height: '70%', backgroundColor: '#fff', boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.2)', }}>
                        <img src={Theatre} style={{ width: '100%', height: '100%' }} />
                    </div>
                    <div style={{ width: '100%', height: '30%', backgroundColor: '#7C827C' }}>
                        <div style={{ paddingTop: 5, marginLeft: 30 }}>
                            <div style={{ fontSize: 18, color: '#FFF', fontFamily: 'FuturaLight', marginTop: 5 }}>THEATRE TYPE ARRANGEMENT</div>
                            <div style={{ fontSize: 14, color: '#FFF', fontFamily: 'FuturaLight', marginTop: 3 }}>SIZE - MURTALA 122.5 m<sup>2</sup> 54 PEOPLE</div>
                            <div style={{ fontSize: 14, color: '#FFF', fontFamily: 'FuturaLight', marginTop: 3 }}>SIZE - KANO 126.6 m<sup>2</sup> 108 PEOPLE</div>
                            <div style={{ fontSize: 14, color: '#FFF', fontFamily: 'FuturaLight', marginTop: 3 }}>SIZE - MURTALA & KANO 252.8 m<sup>2</sup> 97 PEOPLE</div>
                        </div>
                    </div>
                </div>

                <div style={{ width: screenSize.width < 700 ? 350 : 500, height: 450, backgroundColor: '#FFF', marginTop: screenSize.width < 700 ? 20 : 0, marginBottom: 100 }}>
                    <div style={{ fontSize: 18, color: '#000', fontFamily: 'FuturaLight', fontWeight: 'bold', width: '100%', textAlign: 'center', marginTop: screenSize.width < 700 ? 20 : 0 }}>MURTALA & KANO HALLS</div>
                    <div style={{ width: '100%', height: '70%', backgroundColor: '#fff', boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.2)', }}>
                        <img src={Boardroom} style={{ width: '100%', height: '100%' }} />
                    </div>
                    <div style={{ width: '100%', height: '30%', backgroundColor: '#7C827C' }}>
                        <div style={{ paddingTop: 5, marginLeft: 30 }}>
                            <div style={{ fontSize: 18, color: '#FFF', fontFamily: 'FuturaLight', marginTop: 5 }}>BOARDROOM TYPE ARRANGEMENT</div>
                            <div style={{ fontSize: 14, color: '#FFF', fontFamily: 'FuturaLight', marginTop: 3 }}>SIZE - MURTALA 122.5 m<sup>2</sup> 38 PEOPLE</div>
                            <div style={{ fontSize: 14, color: '#FFF', fontFamily: 'FuturaLight', marginTop: 3 }}>SIZE - KANO 126.6 m<sup>2</sup> 38 PEOPLE</div>
                            <div style={{ fontSize: 14, color: '#FFF', fontFamily: 'FuturaLight', marginTop: 3 }}>SIZE - MURTALA & KANO 252.8 m<sup>2</sup> 54 PEOPLE</div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div style={{ width: '100%', marginTop: 100 }}>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ width: 550, height: 700, backgroundColor: '#ccc', marginLeft: 200, position: 'relative' }}>
                        <img src={Conference2} style={{ width: 550, height: 700, position: 'absolute', objectFit: 'cover' }} />
                        <div style={{ position: 'absolute', width: 550, height: 700, alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <div style={{ color: '#fff', fontSize: 50, fontFamily: 'FuturaLight', fontWeight: 'bold' }}>Weddings</div>
                            <div style={{ width: 1, height: 70, backgroundColor: '#FFF', marginTop: 5 }}></div>
                            <div style={{ color: '#fff', fontSize: 100, fontFamily: 'GrandCru', fontWeight: 'bold' }}>01</div>
                            <div style={{ width: 250, height: 70, border: '0.5px solid #FFF', marginTop: 40, display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 20, fontFamily: 'FuturaLight', color: '#fff', cursor: 'pointer' }}>
                                RESERVE A HALL
                            </div>
                        </div>
                    </div>
                    <div style={{ width: 700, height: 550, backgroundColor: '#ccc', marginTop: 150, position: 'relative' }}>
                        <img src={Conference4} style={{ width: 700, height: 550, position: 'absolute', objectFit: 'cover' }} />
                        <div style={{ position: 'absolute', width: 700, height: 550, alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <div style={{ color: '#fff', fontSize: 50, fontFamily: 'FuturaLight', fontWeight: 'bold' }}>Conference</div>
                            <div style={{ width: 1, height: 70, backgroundColor: '#FFF', marginTop: 5 }}></div>
                            <div style={{ color: '#fff', fontSize: 100, fontFamily: 'GrandCru', fontWeight: 'bold' }}>02</div>
                            <div style={{ width: 250, height: 70, border: '0.5px solid #FFF', marginTop: 40, display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 20, fontFamily: 'FuturaLight', color: '#fff', cursor: 'pointer' }}>
                                RESERVE A HALL
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: 100 }}>
                    <div style={{ width: 700, height: 550, backgroundColor: '#ccc', marginTop: 150, position: 'relative' }}>
                        <img src={Conference5} style={{ width: 700, height: 550, position: 'absolute', objectFit: 'cover' }} />
                        <div style={{ position: 'absolute', width: 700, height: 550, alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <div style={{ color: '#000', fontSize: 50, fontFamily: 'FuturaLight', fontWeight: 'bold' }}>Meetings</div>
                            <div style={{ width: 1, height: 70, backgroundColor: '#000', marginTop: 5 }}></div>
                            <div style={{ color: '#000', fontSize: 100, fontFamily: 'GrandCru', fontWeight: 'bold' }}>03</div>
                            <div style={{ width: 250, height: 70, border: '0.5px solid #000', marginTop: 40, display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 20, fontFamily: 'FuturaLight', color: '#000', cursor: 'pointer' }}>
                                RESERVE A HALL
                            </div>
                        </div>
                    </div>
                    <div style={{ width: 550, height: 700, backgroundColor: '#ccc', marginRight: 200, position: 'relative' }}>
                        <img src={Conference7} style={{ width: 550, height: 700, position: 'absolute', objectFit: 'cover' }} />
                        <div style={{ position: 'absolute', width: 550, height: 700, alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <div style={{ color: '#fff', fontSize: 50, fontFamily: 'FuturaLight', fontWeight: 'bold' }}>Events</div>
                            <div style={{ width: 1, height: 70, backgroundColor: '#FFF', marginTop: 5 }}></div>
                            <div style={{ color: '#fff', fontSize: 100, fontFamily: 'GrandCru', fontWeight: 'bold' }}>04</div>
                            <div style={{ width: 250, height: 70, border: '0.5px solid #FFF', marginTop: 40, display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 20, fontFamily: 'FuturaLight', color: '#fff', cursor: 'pointer' }}>
                                RESERVE A HALL
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: 100 }}>
                    <div style={{ width: 550, height: 700, backgroundColor: '#ccc', marginLeft: 200, position: 'relative' }}>
                        <img src={Conference3} style={{ width: 550, height: 700, position: 'absolute', objectFit: 'cover' }} />
                        <div style={{ position: 'absolute', width: 550, height: 700, alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <div style={{ color: '#fff', fontSize: 50, fontFamily: 'FuturaLight', fontWeight: 'bold' }}>Executive Boardrooms</div>
                            <div style={{ width: 1, height: 70, backgroundColor: '#FFF', marginTop: 5 }}></div>
                            <div style={{ color: '#fff', fontSize: 100, fontFamily: 'GrandCru', fontWeight: 'bold' }}>05</div>
                            <div style={{ width: 250, height: 70, border: '0.5px solid #FFF', marginTop: 40, display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 20, fontFamily: 'FuturaLight', color: '#fff', cursor: 'pointer' }}>
                                RESERVE A HALL
                            </div>
                        </div>
                    </div>
                    <div style={{ width: 700, height: 550, backgroundColor: '#ccc', marginTop: 150, position: 'relative' }}>
                        <img src={Conference6} style={{ width: 700, height: 550, position: 'absolute', objectFit: 'cover' }} />
                        <div style={{ position: 'absolute', width: 700, height: 550, alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <div style={{ color: '#000', fontSize: 50, fontFamily: 'FuturaLight', fontWeight: 'bold' }}>Banquet</div>
                            <div style={{ width: 1, height: 70, backgroundColor: '#000', marginTop: 5 }}></div>
                            <div style={{ color: '#000', fontSize: 100, fontFamily: 'GrandCru', fontWeight: 'bold' }}>06</div>
                            <div style={{ width: 250, height: 70, border: '0.5px solid #000', marginTop: 40, display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 20, fontFamily: 'FuturaLight', color: '#000', cursor: 'pointer' }}>
                                RESERVE A HALL
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            {screenSize.width < 700 ? (
                <></>
            ) : (
                <div style={{ width: '100%', height: 800 }} id="targetElement">
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', color: '#000', fontSize: 50, fontFamily: 'FuturaLight', justifyContent: 'center', marginTop: 100, }}>
                        SEATING ARRANGEMENT
                    </div>
                    <div style={{ width: '98%', marginTop: 30, marginLeft: 20 }}>
                        <Table striped bordered hover size="sm" style={{ margin: 'auto', textAlign: 'center', alignItems: 'center' }}>
                            <thead>
                                <tr>
                                    <th style={{ height: '70px', verticalAlign: 'middle' }}></th>
                                    <th colSpan={2} style={{ verticalAlign: 'middle', position: 'relative' }}>A - MURTALA HALL <input type='checkbox' style={{ position: 'absolute', right: 10, marginTop: 5 }} /></th>
                                    <th colSpan={2} style={{ verticalAlign: 'middle', position: 'relative' }}>B - KANO HALL <input type='checkbox' style={{ position: 'absolute', right: 10, marginTop: 5 }} /></th>
                                    <th colSpan={2} style={{ verticalAlign: 'middle', position: 'relative' }}>A + B KANO & MURTALA <input type='checkbox' style={{ position: 'absolute', right: 10, marginTop: 5 }} /></th>
                                </tr>
                                <tr>
                                    <th style={{ height: '70px', verticalAlign: 'middle' }}>SEATING ARRANGEMENT</th>
                                    <th style={{ verticalAlign: 'middle' }}>SIZE (m<sup>2</sup>)</th>
                                    <th style={{ verticalAlign: 'middle' }}>CAPACITY</th>
                                    <th style={{ verticalAlign: 'middle' }}>SIZE (m<sup>2</sup>)</th>
                                    <th style={{ verticalAlign: 'middle' }}>CAPACITY</th>
                                    <th style={{ verticalAlign: 'middle' }}>SIZE (m<sup>2</sup>)</th>
                                    <th style={{ verticalAlign: 'middle' }}>CAPACITY</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ height: '70px', alignItems: 'center', display: 'flex', verticalAlign: 'middle', position: 'relative' }}>U-SHAPE <input type='checkbox' style={{ position: 'absolute', right: 10, marginTop: 5 }} /></td>
                                    <td style={{ verticalAlign: 'middle' }}>122.5 m<sup>2</sup></td>
                                    <td style={{ verticalAlign: 'middle' }}>34 People</td>
                                    <td style={{ verticalAlign: 'middle' }}>126.6 m<sup>2</sup></td>
                                    <td style={{ verticalAlign: 'middle' }}>23 People</td>
                                    <td style={{ verticalAlign: 'middle' }}>252.8 m<sup>2</sup></td>
                                    <td style={{ verticalAlign: 'middle' }}>39 People</td>
                                </tr>
                                <tr>
                                    <td style={{ height: '70px', alignItems: 'center', display: 'flex', verticalAlign: 'middle', position: 'relative' }}>CLASSROOM <input type='checkbox' style={{ position: 'absolute', right: 10, marginTop: 5 }} /></td>
                                    <td></td>
                                    <td></td>
                                    <td style={{ verticalAlign: 'middle' }}>122.5 m<sup>2</sup></td>
                                    <td style={{ verticalAlign: 'middle' }}>108 People</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td style={{ height: '70px', alignItems: 'center', display: 'flex', verticalAlign: 'middle', position: 'relative' }}>ROUNDTABLE <input type='checkbox' style={{ position: 'absolute', right: 10, marginTop: 5 }} /></td>
                                    <td style={{ verticalAlign: 'middle' }}>122.5 m<sup>2</sup></td>
                                    <td style={{ verticalAlign: 'middle' }}>38 People</td>
                                    <td style={{ verticalAlign: 'middle' }}>126.6 m<sup>2</sup></td>
                                    <td style={{ verticalAlign: 'middle' }}>38 People</td>
                                    <td style={{ verticalAlign: 'middle' }}>252.8 m<sup>2</sup></td>
                                    <td style={{ verticalAlign: 'middle' }}>54 People</td>
                                </tr>
                                <tr>
                                    <td style={{ height: '70px', alignItems: 'center', display: 'flex', verticalAlign: 'middle', position: 'relative' }}>THEATRE <input type='checkbox' style={{ position: 'absolute', right: 10, marginTop: 5 }} /></td>
                                    <td style={{ alignItems: 'center', verticalAlign: 'middle' }}>122.5 m<sup>2</sup></td>
                                    <td style={{ verticalAlign: 'middle' }}>54 People</td>
                                    <td style={{ verticalAlign: 'middle' }}>126.6 m<sup>2</sup></td>
                                    <td style={{ verticalAlign: 'middle' }}>108 People</td>
                                    <td style={{ verticalAlign: 'middle' }}>252.8 m<sup>2</sup></td>
                                    <td style={{ verticalAlign: 'middle' }}>97 People</td>
                                </tr>
                            </tbody>
                        </Table>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                            <div></div>
                            <div>
                                <div style={{ marginTop: 0, display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                    <div style={{ width: 250, height: 70, border: '0.5px solid #000', marginTop: 40, display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 20, fontFamily: 'FuturaLight', color: '#fff', cursor: 'pointer', backgroundColor: '#000' }}>
                                        RESERVE A HALL
                                    </div>
                                    <div style={{ color: 'red', marginTop: 10 }}>Please tick the boxes in the table!</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <Footer />
        </div>
    )
}

export default EventScreen