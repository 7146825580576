export const data = [
    // {
    //     image: require('../assets/images/hotelimage/bricks4.jpg')
    // },
    {
        image: require('../assets/images/hotelimage/bricks5.jpg')
    },
    {
        image: require('../assets/images/hotelimage/bricks6.jpg')
    },
    {
        image: require('../assets/images/hotelimage/bricks7.jpg')
    },
    {
        image: require('../assets/images/hotelimage/bricks8.jpg')
    },
    {
        image: require('../assets/images/hotelimage/bricks9.jpg')
    },
    {
        image: require('../assets/images/hotelimage/bricks10.jpg')
    },
    {
        image: require('../assets/images/hotelimage/bricks11.jpg')
    },
    {
        image: require('../assets/images/hotelimage/bricks12.jpg')
    },
    // {
    //     image: require('../assets/images/hotelimage/bricks13.jpg')
    // },
    // {
    //     image: require('../assets/images/hotelimage/bricks14.jpg')
    // },
    // {
    //     image: require('../assets/images/hotelimage/bricks15.jpg')
    // },
    // {
    //     image: require('../assets/images/hotelimage/bricks16.jpg')
    // },
    // {
    //     image: require('../assets/images/hotelimage/bricks17.jpg')
    // },
    // {
    //     image: require('../assets/images/hotelimage/bricks18.jpg')
    // },
    // {
    //     image: require('../assets/images/hotelimage/bricks19.jpg')
    // },
    // {
    //     image: require('../assets/images/hotelimage/bricks20.jpg')
    // },
    // {
    //     image: require('../assets/images/hotelimage/bricks21.jpg')
    // },
    // {
    //     image: require('../assets/images/hotelimage/bricks22.jpg')
    // },
    // {
    //     image: require('../assets/images/hotelimage/bricks23.jpg')
    // },
    // {
    //     image: require('../assets/images/hotelimage/bricks24.jpg')
    // },
    // {
    //     image: require('../assets/images/hotelimage/bricks25.jpg')
    // },
    // {
    //     image: require('../assets/images/hotelimage/bricks26.jpg')
    // },
    // {
    //     image: require('../assets/images/hotelimage/bricks27.jpg')
    // },
    // {
    //     image: require('../assets/images/hotelimage/bricks28.jpg')
    // },
    // {
    //     image: require('../assets/images/hotelimage/bricks29.jpg')
    // },
    // {
    //     image: require('../assets/images/hotelimage/bricks30.jpg')
    // }
]

export const rooms = [
    {
        image: [
            {
                url: require('../assets/images/hotelimage/Studio/studio1.jpg')
            },
            {
                url: require('../assets/images/hotelimage/Studio/studio2.jpg')
            },
            {
                url: require('../assets/images/hotelimage/Studio/studio3.jpg')
            },
            {
                url: require('../assets/images/hotelimage/Studio/studio4.jpg')
            },
            {
                url: require('../assets/images/hotelimage/Studio/studio5.jpg')
            },
            {
                url: require('../assets/images/hotelimage/Studio/studio6.jpg')
            },
        ],
        roomName: 'STUDIO ROOM',
        description: 'Comfortable living space with a blend of functionality and style. A total of seven within the hotel. Two of them have kitchenettes and five are without kitchenettes. '
    },
    {
        image: [
            {
                url: require('../assets/images/hotelimage/special/bedroom.jpg')
            },
            {
                url: require('../assets/images/hotelimage/special/bedroom2.jpg')
            },
            {
                url: require('../assets/images/hotelimage/special/bedroom2.png')
            },
            {
                url: require('../assets/images/hotelimage/special/bedroom3.jpg')
            },
            {
                url: require('../assets/images/hotelimage/special/bedroom4.jpg')
            },
            {
                url: require('../assets/images/hotelimage/special/livingroom2.png')
            },
            {
                url: require('../assets/images/hotelimage/special/livingroom3.jpg')
            },
            {
                url: require('../assets/images/hotelimage/special/livingroom3.png')
            },
            {
                url: require('../assets/images/hotelimage/special/livinigroomcp.png')
            }
        ],
        roomName: '1 BEDROOM SUITE SPECIAL ROOM',
        description: '1 bedroom suite designed for special needs people. '
    },
    {
        image: [
            {
                url: require('../assets/images/hotelimage/1bederoom/onebed1.jpg')
            },
            {
                url: require('../assets/images/hotelimage/1bederoom/onebed2.jpg')
            },
            {
                url: require('../assets/images/hotelimage/1bederoom/onebed3.jpg')
            },
            {
                url: require('../assets/images/hotelimage/1bederoom/onebed4.jpg')
            }
        ],
        roomName: '1 BEDROOM SUITE ',
        description: 'A step up in terms of space and privacy, this comes with a separate living area from the bedroom. Designed to provide privacy and comfort. Furnished with a king-sized bed, and ample storage for luggage. We have a total of eleven one-bedroom suites; Seven come with kitchenettes including the 2 duplexes and three do not.'
    },
    {
        image: [
            {
                url: require('../assets/images/hotelimage/2bedroom/twobed1.jpg')
            },
            {
                url: require('../assets/images/hotelimage/2bedroom/twobed2.jpg')
            },
            {
                url: require('../assets/images/hotelimage/2bedroom/twobed3.jpg')
            },
            {
                url: require('../assets/images/hotelimage/2bedroom/twobed4.jpg')
            }
        ],
        roomName: '2 BEDROOM SUITES',
        description: 'Offers even more space and flexibility, making it an excellent choice for families, groups of friends, or travelers who desire more space. It consists of a sitting area, dinning, kitchenette and two separate bedrooms furnished with king sized beds and ample storage area.'
    },
    {
        image: [
            {
                url: require('../assets/images/hotelimage/ph/ph1.jpg')
            },
            {
                url: require('../assets/images/hotelimage/ph/ph2.jpg')
            },
            {
                url: require('../assets/images/hotelimage/ph/ph3.jpg')
            },
            {
                url: require('../assets/images/hotelimage/ph/ph4.jpg')
            }
        ],
        roomName: 'PENTHOUSE',
        description: 'The only one of its type in the hotel, the Penthouse comes with a private elevator and floor size of 270 square meters. It comes with 2 bedrooms, an office, visitor’s toilet, dining, kitchenette and an oversized outdoor terrace. This apartment comes with an ante-room that is equipped with its own bathroom and wardrobe.'
    },
]

export const taste = [
    {
        img: require('../assets/images/taste/taste1.jpg')
    },
    {
        img: require('../assets/images/taste/taste2.jpg')
    },
    {
        img: require('../assets/images/taste/taste3.jpg')
    },
    {
        img: require('../assets/images/taste/taste6.jpg')
    },
]

export const events = [
    {
        img: require('../assets/images/events2.jpg')
    },
    {
        img: require('../assets/images/events1.jpg')
    },
    {
        img: require('../assets/images/events3.jpg')
    },
]

export const gym = [
    {
        img: require('../assets/images/gym1.jpg')
    },
    {
        img: require('../assets/images/gym2.jpg')
    }
]